import React, { useState, useContext } from "react";
import { UserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

const CreateAdvertisement = () => {
  const [picture, setPicture] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  const createAdvertisement = async (e) => {
    e.preventDefault();

    const advertisementData = new FormData();
    advertisementData.append("picture", picture);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/advertisement`,
        advertisementData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success("Advertisement created successfully!"); // Show success notification
        setPicture(null); // Clear the input field
        e.target.reset(); // Reset the form fields
        // navigate("/"); // Redirect after successful creation
      }
    } catch (err) {
      console.error("Error creating advertisement:", err);
      setError(err.response?.data?.message || "Error creating advertisement");
      toast.error("Failed to create advertisement. Please try again."); // Show error notification
    }
  };

  return (
    <section className="create-post">
      <div className="createcontainer">
        <h2>Create Advertisement</h2>
        {error && <p className="form__error-message">{error}</p>}
        <form className="form create-advertisement__form" onSubmit={createAdvertisement}>
          <input
            type="file"
            onChange={(e) => setPicture(e.target.files[0])}
            accept="image/png, image/jpg, image/jpeg"
            required
          />
          <button type="submit" className="btn primary">
            Create Advertisement
          </button>
        </form>
        <ToastContainer /> {/* Add ToastContainer for Toastify */}
      </div>
    </section>
  );
};

export default CreateAdvertisement;
