import React from "react";
// import './Footer.css';
import { Link } from "react-router-dom";
import FooterLogo from "../images/images/458775590_122100278468513686_6113659977926943335_n.jpg"; // Update with correct path

const Footer = () => {
  return (
    <>
    <footer className="footer-container">
      <div className="footer-logo-address">
        <img src={FooterLogo} alt="Footer Logo" className="footer-logo" />
        <p>
          <a href="mailto:nepalamericanews2024@gmail.com">
            <p>nepalamericanews2024@gmail.com</p>
          </a>
        </p>
        <a href="https://www.prashanna.com/">Prashanna Sangroula</a>
        <p>Ruckersville VA</p>
        <button class="datebutton">
          <Link to="/dateconvertor" className="datenav">
            Date Convertor
          </Link>
        </button>
      </div>

      <div className="footer-links">
        {/* First Row */}
        <div className="footer-section">
          <strong>दैनिक:</strong>
          <p>
            <a href="https://www.dainiknepal.com/">दैनिक नेपाल</a>, <a href="https://www.setopati.com/">सेतोपाटी</a>, <a href="https://www.onlinekhabar.com/">अनलाईन खबर</a>, 
            <a href="https://www.ratopati.com/">रातोपाटी</a>, <a href="https://www.brtnepal.com/">बीआरटीनेपाल</a>, <a href="https://ekantipur.com/">ई-कान्तिपुर</a>, 
            <a href="https://baahrakhari.com/">बाहखरी</a>, <a href="https://gorkhapatraonline.com/">गोरखापत्र</a>
          </p>
        </div>

        {/* Second Row */}
        <div className="footer-section">
          <p>
            <a href="https://ehimalayatimes.com/">द हिमालयन टाइम्स</a>, <a href="https://www.dcnepal.com/">डिसी नेपाल</a>, <a href="https://www.annapurnapost.com/">अन्तरपूर्ण पोष्ट</a>, 
            <a href="https://www.nayapatrikadaily.com/">नयाँ पत्रिका</a>, <a href="https://rajdhanidaily.com/">राजधानी</a>, <a href="https://nagariknews.nagariknetwork.com/">नागरिक दैनिक</a>, 
            <a href="https://myrepublica.nagariknetwork.com/">रिपब्लिका</a>, <a href="https://www.karobardaily.com/">कारोबार</a>
          </p>
        </div>

        <div className="footer-section">
          <p>
            <a href="https://newsofnepal.com/">न्यूज अफ नेपाल</a>, <a href="https://www.nepaljapan.com/">नेपाल जापान</a>, <a href="https://abhiyandaily.com/">अभियान दैनिक</a>, 
            <a href="https://www.nepallivetoday.com/category/nepali-diaspora/">नेपाली डायस्पोरा</a>, <a href="https://sansarnews.com/">संस्कारन्युज</a>
          </p>
        </div>

        {/* Third Row */}
        <div className="footer-section">
          <strong>रेडियो/टेलिभिजन:</strong>
          <p>
            <a href="https://www.bbc.com/nepali">बीबीसी नेपाली सेवा</a>, <a href="https://radionepal.gov.np/">रेडियो नेपाल</a>, <a href="https://www.hitsfm.com.np/">हित्स एफ.एम.</a>
          </p>
        </div>

        {/* Fourth Row */}
        <div className="footer-section">
          <strong>विविध:</strong>
          <p>
            <a href="https://www.nepalipost.com/">नेपाली पोष्ट</a>, <a href="http://www.nepalmother.com/">नेपाल मदर</a>, <a href="https://www.samakalinsahitya.com/">समकालिन साहित्य</a>, 
            <a href="#">खसखस</a>, <a href="https://globenepal.com/">ग्लोब नेपाल</a>, <a href="https://www.youtube.com/channel/UCjG2HX7jfwqIjzTlaF1CPGA">नेपाल २४ आवरस्</a>, 
            <a href="#">एम्प्रेस टाइम्स</a>, <a href="https://www.scotnepal.com/">स्कट नेपाल</a>
          </p>
        </div>

        <div className="footer-section">
          <p>
            <a href="https://main.namasteeurope.com/">नमस्ते यूरोप</a>, <a href="https://www.khasokhas.com/#gsc.tab=0">खसोखस</a>, <a href="https://www.texasnepal.com/">टेक्सास नेपाल</a>, 
            <a href="https://khabarmala.com/">खबरमाला</a>, <a href="#">खबर सम्र्पेण</a>
          </p>
        </div>

        {/* Fifth Row */}
        <div className="footer-section">
          <strong>नेपाली युनिकोड:</strong>
          <p>
            <a href="https://www.easynepalityping.com/">नेपालीमा टाइप</a>, <a href="https://preeti.arthasarokar.com/">प्रितिबाट युनिकोड</a>, <a href="https://mylove.com.np/Roman%20to%20Unicode%20Nepali%20Converter.htm">रोमनबाट युनिकोड</a>
          </p>
        </div>
      </div>
      
    </footer>
    <div className="footcopy"><p>© nepalamericanews 2024. All rights reserved.</p></div>
    </>
  );
};


export default Footer;
