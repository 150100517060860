import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

const CreateEvent = () => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [price, setPrice] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [picture, setPicture] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  const createEvent = async (e) => {
    e.preventDefault();

    const eventData = new FormData();
    eventData.append("picture", picture);
    eventData.append("date", new Date(date).toISOString());
    eventData.append("title", title);
    eventData.append("price", price);
    eventData.append("short_description", shortDescription);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/events`,
        eventData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success("Event created successfully!"); // Show success notification
        // Clear the input fields
        setTitle("");
        setDate("");
        setPrice("");
        setShortDescription("");
        setPicture(null);
        // Optionally, you can redirect the user if needed
        // navigate("/");
      }
    } catch (err) {
      console.error("Error creating event:", err);
      setError(err.response?.data?.message || "Error creating event");
      toast.error("Failed to create event. Please try again."); // Show error notification
    }
  };

  return (
    <section className="create-post">
      <div className="createcontainer">
        <h2>Create Event</h2>
        {error && <p className="form__error-message">{error}</p>}
        <form className="form create-event__form" onSubmit={createEvent}>
          <input
            type="text"
            placeholder="Event Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <input
            type="date"
            placeholder="Event Date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Optional"
          />
          <textarea
            placeholder="Short Description"
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
            required
          />
          <input
            type="file"
            onChange={(e) => setPicture(e.target.files[0])}
            accept="image/png, image/jpg, image/jpeg"
            required
          />
          <button type="submit" className="btn primary">
            Create Event
          </button>
        </form>
        <ToastContainer /> {/* Add ToastContainer to render toast notifications */}
      </div>
    </section>
  );
};

export default CreateEvent;
